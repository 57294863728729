import React from 'react';
import { getReportGroupIcon, IReportGroup } from '../../entities/ReportGroup';

interface IComponentInfo {
    group: IReportGroup;
}

const ReportSectionLabel: React.FunctionComponent<IComponentInfo> = ({ group }) => {
    return (
        <>
            <img alt={group.title} className="report-icon m-r-5" src={getReportGroupIcon(group.configuration)} ></img>{group.title}
        </>
    );
};

export default ReportSectionLabel;
