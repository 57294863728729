import React, { useEffect, useState } from 'react';
import OEDropdown, { IDropdownValues } from '../../../../core/components/form/OEDropdown';
import { OEFormGroup, OEFormLabel } from '../../../../core/components/form/OEForm';
import { parseQueryString } from '../../../../core/utilities/URL';
import { defaultReportPage, IReportPage } from '../../../../reporting/entities/ReportPage';
import { useGetAcademicYears } from '../../../../reporting/services/ReportFilterService';
import { useGetReportPageDataDownload } from '../../../../reporting/services/ReportPageService';
import { saveSiteSetting, SiteSetting } from '../../../../site/entities/SiteSettings';
import GroupList from './GroupList';

declare global { var runCode: any; }
declare global { var microstrategy: any; }

interface IComponentInfo {
    msPath: string;
}

const MSDataDownload: React.FunctionComponent<IComponentInfo> = ({ msPath }) => {
    const params: any = parseQueryString();
    const { service: pageService, setPageId } = useGetReportPageDataDownload('');
    const { service: yearService } = useGetAcademicYears(true);

    const [years, setYears] = useState<IDropdownValues[]>([]);
    const [year, setYear] = useState<string>('');
    const [page, setPage] = useState<IReportPage>(defaultReportPage);
    const [pageParameterId] = useState(params['pid'] || '');
    const [openSection, setOpenSection] = useState<any>([]);

    useEffect(() => {
        if (pageService.result) {
            setPage(pageService.result);
        }
        // eslint-disable-next-line
    }, [pageService]);

    useEffect(() => {
        if (yearService.result) {
            setYears(yearService.result);
        }
        // eslint-disable-next-line
    }, [yearService]);

    useEffect(() => {
        if (years.length > 0) {
            setYear(years[0].id);
        }
        // eslint-disable-next-line
    }, [years]);

    const onChangeYear = (i: string) => {
        setYear(i);
        saveSiteSetting(SiteSetting.DownloadAcademicYear, year);
        setPageId(pageParameterId);
    }

    useEffect(() => {
        setPageId(pageParameterId);
        // eslint-disable-next-line
    }, [pageParameterId]);

    return (
        <div className='p30'>
            <h2 className='default-title-body'>Datasets</h2>
                <OEFormGroup inline={true} className="m-r-5">
                    <OEFormLabel>Academic Year</OEFormLabel>
                    <OEDropdown width="100px"  values={years} value={year} onChange={onChangeYear} />
                </OEFormGroup>
            <ul className='no-p'>
                {page.groups.filter(q => q.isActive).map((sl) =>
                    <GroupList
                        level={0}
                        parentGroups={page.groups.length}
                        msPath={msPath}
                        key={sl.id}
                        group={sl}
                        year={year}
                        pageConfigruation={page.configuration}
                        openSection={openSection} setOpenSection={(id) => {
                            if (openSection.includes(id)) {
                                let filteredSection = openSection.filter((section: string) => section !== id);
                                setOpenSection(filteredSection);
                            } else setOpenSection([...openSection, id])
                        }} />
                )}
            </ul>
            {/* {pageParameterId} */}
        </div>
    );
};

export default MSDataDownload;
