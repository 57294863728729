import { useEffect, useState } from 'react';
import { IBaseList } from '../../core/entities/BaseList';
import { DeleteRequest, processDeleteAsync } from '../../core/services/DeleteRequest';
import { GetRequest, processGetAsync, processGetPostAsync } from '../../core/services/GetRequest';
import { PostRequest, processPostAsync } from '../../core/services/PostRequest';
import { processPutAsync, PutRequest } from '../../core/services/PutRequest';
import { IModule } from '../entities/Module';
import { defaultRoute, IRoute, IRouteModuleSearch } from '../entities/Route';

export const useGetRoutes = () => {
    const [service, setService] = useState(GetRequest.empty<IBaseList<IRoute>>());
    const [refresh, setRefresh] = useState(true);
    const doRefresh = () => setRefresh(true);

    useEffect(() => {
        if (!refresh) { return; }
        processGetAsync(setService, `/api/app/routes?MaxResultCount=1000`);
        setRefresh(false);
    }, [refresh]);

    return { service, doRefresh };
};

export const useGetUserRoutes = () => {
    const [service, setService] = useState(GetRequest.empty<IBaseList<IRoute>>());
    const [refresh, setRefresh] = useState(false);
    const doRefresh = () => setRefresh(true);

    useEffect(() => {
        if (!refresh) { return; }
        processGetAsync(setService, `/api/app/routes/user-routes?MaxResultCount=1000`);
        setRefresh(false);
    }, [refresh]);

    return { service, doRefresh };
};

export const usePutRoute = () => {
    const [service, setService] = useState(PutRequest.empty());
    const [route, setRoute] = useState<IRoute>(defaultRoute);

    useEffect(() => {
        if (isNaN(route.id) || route.id <= 0) { return; }
        processPutAsync(setService, `/api/app/routes/${route.id}`, route);
        setRoute(defaultRoute);
    }, [route]);

    return { service, setRoute };
};

export const useCreateRoute = () => {
    const [service, setService] = useState(PostRequest.empty());
    const [route, setRoute] = useState<IRoute>(defaultRoute);

    useEffect(() => {
        if (route.name === '') { return; }
        processPostAsync(setService, `/api/app/routes`, route);
        setRoute(defaultRoute);
    }, [route]);

    return { service, setRoute };
};

export const useDeleteRoute = () => {
    const [service, setService] = useState(DeleteRequest.empty());
    const [routeId, setRouteId] = useState<number>(-1);

    useEffect(() => {
        if (routeId < 0) { return; }
        processDeleteAsync(setService, `/api/app/routes/${routeId}`);
        setRouteId(-1);
    }, [routeId]);

    return { service, setRouteId };
};

export const useGetModules = () => {
    const [service, setService] = useState(GetRequest.empty<IBaseList<IModule>>());
    const [routeId, setRouteId] = useState<number>(-1);

    useEffect(() => {
        if (routeId < 0) { return; }
        processGetAsync(setService, `/api/app/modules?RouteId=${routeId}&MaxResultCount=1000`);
        setRouteId(-1);
    }, [routeId]);

    return { service, setRouteId };
};

export const useGetUserModules = () => {
    const [service, setService] = useState(GetRequest.empty<IBaseList<IModule>>());
    const [route, setRoute] = useState<IRoute>(defaultRoute);

    useEffect(() => {
        if (route.path === '') { return; }

        const p: IRouteModuleSearch = { path: route.path };
        processGetPostAsync(setService, `/api/app/modules/user-modules`, p);
        setRoute(defaultRoute);
    }, [route]);

    return { service, setRoute };
};

