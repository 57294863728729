import { getSiteSetting, SiteSetting } from "../../site/entities/SiteSettings";

export const displayHTMLContent = (data: string): any => {
    return { __html: data };
};


export const canDebug = (): boolean => {
    try {
        return getSiteSetting(SiteSetting.CanDebug);
    }
    catch {
        return false;
    }
}