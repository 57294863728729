import React from 'react';

interface IComponentInfo {
    icon: string;
    className?: string;
    text?: string;
    title?: string;
    hidden?: boolean;
    onClick?: () => void;
}

const OEIcon: React.FunctionComponent<IComponentInfo> = ({ icon, text, title, className, hidden, onClick }) => {
    return (
        <span tabIndex={onClick ? 0 : -1} onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') onClick && onClick()
        }} hidden={hidden} onClick={onClick} className={`${className ? className : ''} ${onClick ? 'icon-action' : ''}`} >
            <i title={title} className={`${icon} `} />
            {text && (
                <span>{text}</span>
            )}
        </span>
    );
};

export default OEIcon;
