import React, { Fragment, useEffect, useState } from 'react';
import { Icon } from '../../entities/Icon';
import { formatDateTime } from '../../utilities/Date';
import { displayHTMLContent } from '../../utilities/Miscellaneous';
import { formatCurrency, formatDecimal, formatInteger, formatPercent } from '../../utilities/String';
import OEButton from '../form/OEButton';
import { OEFormControl } from '../form/OEForm';
import OELink from '../general/OELink';
import { ColumnType, getColumnStyle, IColumn } from './entities/Column';

function getData(column: IColumn, data: any) {
    // tslint:disable
    switch (column.type) {
        case ColumnType.String:
        case ColumnType.Link:
            if (column.text) {
                return column.text;
            }
            if (data && column.maxLength && data.length > column.maxLength) {
                return `${data.toString().substring(0, column.maxLength)} ...`;
            }
            return data;

        case ColumnType.Integer:
            return formatInteger(data ? data : 0);

        case ColumnType.Decimal:
            return formatDecimal(data, column.percision || 2);

        case ColumnType.Currency:
            return formatCurrency(data ? data : 0, column.percision);

        case ColumnType.Percentage:
            return formatPercent(data / 100, column.percision || 0);

        case ColumnType.PercentageExact:
            return formatPercent(data, column.percision || 0);

        case ColumnType.Date:
            const d: string = formatDateTime(data, column.format || 'mm/dd/yyyy');
            return d;

        case ColumnType.StringArray:
            let s: string = '';
            if (data && data.length > 0) {
                for (const i of data) {
                    s = `${s}${s.length > 0 ? ', ' : ''}${i}`
                }
            }
            return s;

        case ColumnType.StringSelector:
            if (column.values && data) {
                for (const i of column.values) {
                    if (i.id.toString() === data.toString()) {
                        return i.name;
                    }
                }
            }
            return 'N/A';

        case ColumnType.Boolean:
            return data ? 'YES' : 'NO';

        case ColumnType.RowNumber:
        case ColumnType.ID:
            if (column.hideZero && parseInt(data, 0) === 0) {
                return '';
            }
            return data;

        case ColumnType.Text:
            return column.id;
    }
    // tslint:enable

    return 'Type Not defined';
}

const specialDisplay = (column: IColumn) => {
    return (
        column.displayHTML
        || column.type === ColumnType.Check
        || column.type === ColumnType.Checkbox
        || column.type === ColumnType.Empty
        || column.type === ColumnType.Image
        || column.type === ColumnType.NavigationLink
        || column.type === ColumnType.Time
        || column.type === ColumnType.Button
        || column.type === ColumnType.Icon
    );
};

export interface IColumnInfo {
    column: IColumn;
    data: any;
    data2?: string;
    item?: any;
    divider?: any;
    index: number;
    timezone?: string;
}

const OEColumn: React.FunctionComponent<IColumnInfo> = ({ column, data, data2, item, index, timezone }) => {

    const [displayData, setDisplayData] = useState<string>('');

    useEffect(() => {
        setDisplayData(getData(column, data));
        // eslint-disable-next-line
    }, [data]);

    const onClick = () => {
        column.onClick && column.onClick(item);
    };

    const onSelect = (event: any) => {
        column.onSelect && column.onSelect(item, event.target.checked);
    };


    return (
        <Fragment>
            <td style={getColumnStyle(column)}
                key={column.id}
                className={`${column.classNameColumn !== undefined ? item[column.classNameColumn] : ''} ${column.className || (column.type === ColumnType.Check || column.type === ColumnType.Checkbox ? 'text-center' : '')}`}
            >
                <Fragment>
                    <div>
                        {column.icon && column.icon.length > 0 && column.type !== ColumnType.Button && column.type !== ColumnType.Link && (
                            <span>
                                <i className={`${column.icon} m-r-5`} />
                            </span>
                        )}
                        {column.iconColumn && column.iconColumn.length > 0 && (
                            <span>
                                <i className={`${item[column.iconColumn]} m-r-5`} />
                            </span>
                        )}
                        {column.displayHTML && (
                            <div dangerouslySetInnerHTML={displayHTMLContent(data)} />
                        )}
                        {!specialDisplay(column) && (
                            <Fragment>
                                {column.type === ColumnType.Link && (
                                    <OELink icon={column.icon} onClick={onClick} title={column.helpText || ''} text={displayData} item={item} className={column.className} />
                                )}
                                {column.type !== ColumnType.Link && (
                                    <Fragment>
                                        {displayData}
                                    </Fragment>
                                )}
                            </Fragment>
                        )}
                        {column.type === ColumnType.Button && (
                            <OEButton bStyle={column.buttonStyle} onClick={onClick} text={column.text} icon={column.icon} />
                        )}
                        {column.type === ColumnType.Check && (
                            <span>
                                {data ? <i className={Icon.CheckYes} /> : <i className={Icon.CheckNo} />}
                            </span>
                        )}
                        {column.type === ColumnType.Checkbox && (
                            <OEFormControl
                                id={`checkbox${index}`}
                                type="checkbox"
                                checked={data}
                                onChange={onSelect} />
                        )}
                        {column.type === ColumnType.NavigationLink && (
                            <OELink href={`${data}`} text={data} target={column.target || '_same'} />
                        )}
                        {column.type === ColumnType.Icon && (
                            <span>
                                <i className={`${data}`} />
                            </span>
                        )}
                        {column.type === ColumnType.Image && data && (
                            <img alt="data_image" src={`${column.baseURL ? column.baseURL : ''}${data}`} onClick={onClick} width={column.width} />
                        )}
                        {column.type === ColumnType.Time && (
                            <Fragment>
                                {`${formatDateTime(data, 'st')}`}
                            </Fragment>
                        )}
                        {data2 && (
                            <i>
                                <br />
                                {data2}
                            </i>
                        )}
                    </div>
                </Fragment>
            </td>
        </Fragment>
    );
};

export default OEColumn;