import React, { Children, CSSProperties } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

interface IComponentInfo {
    className?: string;
    tooltipClass?: string;
    tooltipPlacement?: any;
    icon?: string;
    item?: any;
    title?: string;
    placement?: string;
    text?: string;
    href?: string;
    download?: string;
    onMouseOver?: () => void;
    onMouseOut?: () => void;
    target?: string;
    onClick?: (item: any) => void;
    id?: string;
    style?: CSSProperties;
    dataToggle?: string;
    dataBsToggle?: string;
    ariaExpanded?: boolean;
    hidden?: boolean;
    children?: any;
}

const OELink: React.FunctionComponent<IComponentInfo> = ({
    item, className, tooltipClass, icon, text, title, onClick, href,
    target, onMouseOut, onMouseOver, style, download,
    children, id, dataToggle, dataBsToggle, ariaExpanded,
    hidden, tooltipPlacement
}) => {

    const onLinkClicked = () => {
        onClick && onClick(item);
    };

    return (
        <>
            {!hidden &&
                <OverlayTrigger
                    placement={tooltipPlacement || "top"}
                    overlay={
                        <Tooltip className={tooltipClass || ''} hidden={!title} id="OELinkToolTip">
                            {title}
                        </Tooltip>
                    }
                >
                    <a
                        tabIndex={0}
                        style={{ ...style, cursor: 'pointer' }}
                        target={target}
                        data-toggle={dataToggle}
                        data-bs-toggle={dataBsToggle}
                        aria-expanded={ariaExpanded}
                        className={className || ''}
                        href={href}
                        id={id || 'OELink'}
                        onMouseOver={onMouseOver}
                        download={download}
                        onMouseOut={onMouseOut}
                        onClick={onLinkClicked}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') onLinkClicked()
                        }}
                    >
                        {icon && (
                            <i className={icon} title={`${text ?? ''}`}/>
                        )}
                        {text && (
                            <>
                                {text}
                            </>
                        )}
                        {Children.map(children, i => i)}
                    </a>
                </OverlayTrigger>
            }
        </>
    );
};

export default OELink;
