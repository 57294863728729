import React, { useEffect, useState } from 'react';
import UserMessageAlertsList from '../../../alerts/components/UserMessageAlertsList';
import WidgetContent from '../../../widgets/components/WidgetContent';
import { getWidgetList, IWidget } from '../../../widgets/entities/Widget';
import { getWidgetGroupList } from '../../../widgets/entities/WidgetGroup';
import { useGetWidgetGroupByCode } from '../../../widgets/services/WidgetGroupService';
import { useGetWidgetsByGroupId } from '../../../widgets/services/WidgetService';
import { ISiteSettings } from '../../entities/SiteSettings';

interface IComponentInfo {
    settings: ISiteSettings;
}

const Dashboard: React.FunctionComponent<IComponentInfo> = () => {
    const { service: groupService } = useGetWidgetGroupByCode('HOMEPAGE');
    const { service, setGroupId } = useGetWidgetsByGroupId();
    const [items, setItems] = useState<IWidget[]>([]);

    useEffect(() => {
        if (groupService.result) {
            setGroupId(getWidgetGroupList(groupService.result)[0].id)
        }
        // eslint-disable-next-line
    }, [groupService]);

    useEffect(() => {
        if (service.result) {
            setItems(getWidgetList(service.result));
        }
    }, [service]);

    return (
        <>
            <div className="container m-t-40">
                <WidgetContent items={items} code='CONTENT' />

                <div className="row">
                    <div className="col-8 m-t-40">
                        <WidgetContent items={items} code='FOOTER1' />
                        <UserMessageAlertsList />

                    </div>
                    <div className="col-md-4 m-t-40">
                        <WidgetContent items={items} code='FOOTER2' />
                    </div>
                    <div className="col-xs-12 col-md-11">
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dashboard;