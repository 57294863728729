export const padZeroStart = (value: number, length: number) => {
    return value.toString().padStart(length, '0');
};

export const formatInteger = (d: any) => {
    try {
        return parseInt(d, 10).toFixed().toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    } catch (e) {
        return 'undefined';
    }
};

export const formatDecimal = (d: any, percision: number) => {
    try {
        return parseFloat(d.toString()).toFixed(percision).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    } catch (e) {
        return 'undefined';
    }
};

export const formatPercent = (d: number, percision: number) => {
    try {
        return `${(d * 100).toFixed(percision).toString()}%`;
    } catch (e) {
        return 'undefined';
    }
};

export const formatPercentExact = (d: number, percision: number) => {
    try {
        return `${(d).toFixed(percision).toString()}%`;
    } catch (e) {
        return 'undefined';
    }
};

export const formatCurrency = (d: number, percision?: number): string => {
    return `$${formatDecimal(d, percision || 2).replace(/(\.[0-9][0-9])0+$/, '$1')}`;
};

export const getEmptyGUID = (): string => {
    return '00000000-0000-0000-0000-000000000000';
};

export const getBlankGUID = (s: string): boolean => {
    return s === '' || s === getEmptyGUID();;
};

export const emptyGUID = (s: string): boolean => {
    
    return !s || s==='' || s.replace(/-/g, '').replace(/0/g, '') === '';
}
