import { useEffect, useState } from 'react';
import { IBaseList } from '../../core/entities/BaseList';
import { GetRequest, processGetAsync } from '../../core/services/GetRequest';
import { IReportFilterListItem } from '../../report-microstrategy/entities/ReportFilter';
import { IReportDropdownData } from '../entities/ReportDropdownData';
import { SiteReportIds } from '../entities/ReportType';
import { defaultSchoolDistrictSearch, ISchoolDistrictSearch } from '../entities/SchoolDistrctSearch';

export const useGetEmbeddedReportFilters = (b: boolean) => {
    const [service, setService] = useState(GetRequest.empty<IBaseList<IReportFilterListItem>>());
    const [refresh, setRefresh] = useState(b);
    const doRefresh = () => setRefresh(true);

    useEffect(() => {
        if (!refresh) { return; }
        processGetAsync(setService, `/api/app/report-filters/?MaxResultCount=1000&ReportId=${SiteReportIds.EmbeddedFilters}`);
        setRefresh(false);
    }, [refresh]);

    return { service, doRefresh };
};

export const useGetReportHeader = () => {
    const [service, setService] = useState(GetRequest.empty<IReportDropdownData[]>());
    const [keyword, setKeyword] = useState<string>('');

    useEffect(() => {
        if (keyword === '') { return; }
        processGetAsync(setService, `/api/app/reports/report-header?keyword=${keyword}`);
        setKeyword('');
    }, [keyword]);

    return { service, setKeyword };
};

export const useGetSchoolDistricts = () => {
    const [service, setService] = useState(GetRequest.empty<IReportDropdownData[]>());
    const [search, setSearch] = useState<ISchoolDistrictSearch>(defaultSchoolDistrictSearch);

    useEffect(() => {
        if (search.keyword === '') { return; }
        processGetAsync(setService, search.isSchool ? `/api/app/reports/school-finder?keyword=${search.keyword}&academicyear=${search.academicYear}` : `/api/app/reports/districts-schools?keyword=${search.keyword}&academicyear=${search.academicYear}`);
        setSearch(defaultSchoolDistrictSearch);
    }, [search]);

    return { service, setSearch };
};

export const useGetAcademicYears = (b: boolean) => {
    const [service, setService] = useState(GetRequest.empty<IReportDropdownData[]>());
    const [refresh, setRefresh] = useState(b);
    const doRefresh = () => setRefresh(true);

    useEffect(() => {
        if (!refresh) { return; }
        processGetAsync(setService, `/api/app/reports/academic-years`);
        setRefresh(false);
    }, [refresh]);

    return { service, doRefresh };
};