import React, { useEffect, useState } from 'react';
import OELink from '../../core/components/general/OELink';
import OENotification from '../../core/components/messaging/OENotification';
import { INotification, defaultNotification } from '../../core/components/messaging/entities/Notification';
import { getUrlParameters } from '../../core/components/parameters/entities/OEParameter';
import { IProfile, defaultProfile } from '../../user/entities/Profile';
import { IProfileImage, defaultProfileImage, getImageSource } from '../../user/entities/ProfileImage';
import { useGetProfileImage } from '../../user/services/ProfileImageService';
import { useGetProfile } from '../../user/services/ProfileService';
import { IMenu } from '../entities/Menu';
import { ISiteMenu } from '../entities/SiteMenu';
import { ISiteSettings, SiteLanguages, SiteSetting, getCurrentLanguage, getSiteSetting, saveSiteSetting } from '../entities/SiteSettings';
import { useGetUserMenus } from '../services/MenuService';
import '../styles/NavMenu.css';
import Menu from './sitemenu/Menu';

export const saveRootMenu = (i: ISiteMenu) => {
    saveSiteSetting(SiteSetting.NimbleSelectedMenu, i.rootId.toString());
}

interface IComponent {
    onLogout: () => void;
    maintenance: boolean;
    settings: ISiteSettings;
}

const SiteMenu: React.FunctionComponent<IComponent> = ({ onLogout, maintenance, settings }) => {
    const path: string = window.location.href;

    const { service } = useGetUserMenus();
    const { service: profileService } = useGetProfile();
    const { service: imageService } = useGetProfileImage();

    const [siteMenu, setSiteMenu] = useState<ISiteMenu[]>([]);
    const [profile, setProfile] = useState<IProfile>(defaultProfile);
    const [profileImage, setProfileImage] = useState<IProfileImage>(defaultProfileImage);
    const [notification, setNotification] = useState<INotification>(defaultNotification);
    const [language, setLanguage] = useState<string>(getCurrentLanguage());

    const handleTranslateClick = (lang: string) => {
        if (lang !== language) {
            translateLanguage(lang);
            saveSiteSetting(SiteSetting.TranslateLanguage, lang);
            setLanguage(lang);
        }
    };

    useEffect(() => {
        if (imageService.result) {
            setProfileImage(imageService.result);
        }
    }, [imageService]);

    useEffect(() => {
        if (service.result) {
            setSiteMenu(getChildren(service.result.items.filter(q=>q.isActive), 0, 0));
        }
        // eslint-disable-next-line
    }, [service]);

    useEffect(() => {
        if (profileService.result) {
            setProfile(profileService.result);
        }
    }, [profileService]);

    const translateLanguage = (lang: string) => {
        const googleTranslateDropdown: any = document.querySelector(".goog-te-combo");
        if (googleTranslateDropdown && lang) {
            googleTranslateDropdown.value = lang;
            googleTranslateDropdown.dispatchEvent(new Event('change'));
            saveSiteSetting(SiteSetting.TranslateLanguage, lang);
        }
        if (googleTranslateDropdown && !googleTranslateDropdown.value && lang) {
            googleTranslateDropdown.value = lang;
            googleTranslateDropdown.dispatchEvent(new Event('change'));
            saveSiteSetting(SiteSetting.TranslateLanguage, lang);
        }
    }

    const getChildren = (p: IMenu[], pid: number, rid: number): ISiteMenu[] => {
        const l: ISiteMenu[] = [];
        for (const i of p.filter(q => q.parentId === pid)) {
            i.rootId = pid > 0 ? rid : i.id;
            const f: ISiteMenu = { ...i, children: getChildren(p, i.id, i.rootId) };
            f.childCount = f.children.length;
            if (f.parameters !== '') {
                f.path = `${f.path}${getUrlParameters(f.parameters)}`;
            }

            l.push(f);
        }
        return l;
    }

    const isActive = (m: ISiteMenu): boolean => {

        if (m.path === '/home' && path.endsWith(window.location.host + '/'))
            return true;

        if (getSiteSetting(SiteSetting.NimbleSelectedMenu) === m.rootId.toString() && !path.endsWith(window.location.host + '/'))
            return true;

        return false;
    }

    const inContainer = (): boolean => {
        if (path.includes("reportmsiframe")) {
            return false;
        }
        return true;
    }

    return (
        <>
            <OENotification setNotification={setNotification} notification={notification} />
            {siteMenu.length > 0 && (
                <>
                    <nav className="py-2 border-bottom">
                        <div className={`${inContainer() ? 'm-l-10' : 'm-l-10'} d-flex flex-wrap`}>
                            <a
                                href={settings.homePageLogoLink || '/'}
                                className="d-flex align-items-center mb-3 mb-lg-0 me-lg-auto text-decoration-none"
                                aria-label="Home page"
                            >
                                <img className="header-logo" src={`/images/header-logo.png?v=${Math.random() * 1000}`} alt="Home" title="Home" />
                                <span className="visually-hidden">Home</span>
                            </a>
                            <div className="text-end d-flex m-r-20" style={{ alignItems: 'start', height: 40 }} onClick={() => handleTranslateClick(language === SiteLanguages.English ? SiteLanguages.Spanish : SiteLanguages.English)}>
                                <img src={'images/lang_translator.png'} alt="mdo" width="30" height="30" style={{ marginRight: 10 }} />
                                <p className="skip-to-content" style={{ textDecoration: 'underline', color: '#479BD7' }} >
                                    {language === "en" ? `Translate to Espanol` : "Translate to Inglesa"}
                                </p>
                            </div>
                            {!maintenance && (
                                <div className="dropdown text-end">
                                    <OELink className="d-block text-decoration-none dropdown-toggle link-dark" id="dropdownUser1" dataBsToggle="dropdown" ariaExpanded={false}>
                                        <img src={getImageSource(profileImage)} alt="mdo" width="32" height="32" className="rounded-circle" />
                                        <span className="m-l-5 fs-6 link-dark">{`${profile.name} ${profile.surname}`}</span>
                                    </OELink>
                                    <ul className="dropdown-menu text-small" aria-labelledby="dropdownUser1">
                                        <li><OELink href="/userprofile" className="dropdown-item" >Profile</OELink></li>
                                        <li><hr className="dropdown-divider" /></li>
                                        <li><OELink href="/about" className="dropdown-item" >About</OELink></li>
                                        <li><hr className="dropdown-divider" /></li>
                                        <li><OELink className="dropdown-item" onClick={onLogout} >Log out</OELink></li>
                                    </ul>
                                </div>
                            )}
                        </div>
                    </nav>
                    <header className="bg-dark text-white main-nav">
                        {!maintenance && (
                            <>
                                {siteMenu.filter(q => q.parentId === 0 && q.isActive).map((item, index) =>
                                    <ul className={`otis-menu ${index > 0 ? '' : 'm-l-40'}`} key={item.id} >
                                        <Menu item={item} isActive={isActive(item)} />
                                    </ul>
                                )}
                            </>
                        )}
                    </header>
                </>
            )}
        </>
    );

};

export default SiteMenu;
