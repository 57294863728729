import { useEffect, useState } from 'react';
import { getCookie } from '../../core/services/Authentication';
import { ICubeRequest, defaultCubeRequest } from '../entities/api/CubeRequest';
import { getSiteSetting, SiteSetting } from '../../site/entities/SiteSettings';
declare global { var msRestAPI: any; }

export interface IMSRestAPIService {
    body: any;
    success: boolean;
    isInProgress: boolean;
    error: string;
}

export const defaultIMSRestAPIService: IMSRestAPIService = {
    body: undefined, success: false, isInProgress: false, error: ''
};

export const getMSAuthorizationToken = (): any => {
    return getCookie('MS-TOKEN');
};

export const setMSAuthorizationToken = (token: string): any => {
    var expires = (new Date(Date.now() + (3600 * 1000))).toUTCString();
    document.cookie = `MS-TOKEN=${token}; expires=${expires}; path=/;`
};

const getCubeOptions = (cube: ICubeRequest): any => {
    return {
        method: "GET",
        // Including cookie
        credentials: "include",
        headers: {
            "content-type": "application/json",
            "x-mstr-authtoken": getMSAuthorizationToken(),
            "x-mstr-ProjectID": cube.projectId,
        }
    };
}

const postCubeOptions = (cube: ICubeRequest): any => {
    return {
        method: "POST",
        // Including cookie
        credentials: "include",
        headers: {
            "content-type": "application/json",
            "x-mstr-authtoken": getMSAuthorizationToken(),
            "x-mstr-ProjectID": cube.projectId,
        },
        body: JSON.stringify(cube.body)
    };
}

export const useGetCube = () => {
    const [service, setService] = useState<IMSRestAPIService>(defaultIMSRestAPIService);
    const [cube, setCube] = useState<ICubeRequest>(defaultCubeRequest);

    useEffect(() => {
        if (!cube.run) { return; }
        setService({ ...defaultIMSRestAPIService, isInProgress: true });
        msRestAPI(getSiteSetting(SiteSetting.MSPath), `cubes/${cube.id}`, getCubeOptions(cube) , setService);
        setCube(defaultCubeRequest);
    }, [cube]);

    return { service, setCube };
};

export const useGetCubeAttributeElements = () => {
    const [service, setService] = useState<IMSRestAPIService>(defaultIMSRestAPIService);
    const [cube, setCube] = useState<ICubeRequest>(defaultCubeRequest);

    useEffect(() => {
        if (!cube.run) { return; }
        setService({ ...defaultIMSRestAPIService, isInProgress: true });
        msRestAPI(getSiteSetting(SiteSetting.MSPath), `cubes/${cube.id}/attributes/${cube.attributeId}/elements?limit=-1`, getCubeOptions(cube), setService);
        setCube(defaultCubeRequest);
    }, [cube]);

    return { service, setCube };
};

export const usePostCubeAttributeElements = () => {
    const [service, setService] = useState<IMSRestAPIService>(defaultIMSRestAPIService);
    const [cube, setCube] = useState<ICubeRequest>(defaultCubeRequest);

    useEffect(() => {
        if (!cube.run) { return; }
        setService({ ...defaultIMSRestAPIService, isInProgress: true });
        msRestAPI(getSiteSetting(SiteSetting.MSPath), `cubes/${cube.id}/instances?offset=0&limit=-1`, postCubeOptions(cube), setService);
        setCube(defaultCubeRequest);
    }, [cube]);

    return { service, setCube };
};

export const useGetDossierDefinition = () => {
    const [service, setService] = useState<IMSRestAPIService>(defaultIMSRestAPIService);
    const [cube, setCube] = useState<ICubeRequest>(defaultCubeRequest);

    useEffect(() => {
        if (!cube.run) { return; }
        setService({ ...defaultIMSRestAPIService, isInProgress: true });
        msRestAPI(getSiteSetting(SiteSetting.MSPath), `dossiers/${cube.dossierId}/definition`, getCubeOptions(cube), setService);
        setCube(defaultCubeRequest);
    }, [cube]);

    return { service, setCube };
};



// https://dev-reports.nddpistarsreporting.nd.gov/MicroStrategyLibrary/api/cubes/50437D7C47A873E2A737089A629ACD1D/instances?offset=0&limit=1000' \
// call the rest api of cascading priority


// cube id  50437D7C47A873E2A737089A629ACD1D for report id da2e6b07-b834-b6b3-2a88-3a09df2f013b