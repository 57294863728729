import React from 'react';
import { IUser } from '../../../user/entities/User';
import { OECoreComponent } from '../../components/OECoreComponent';
import { ISiteSettings } from '../../entities/SiteSettings';

interface ICoreComponentInfo {
    name: string;
    user: IUser;
    settings: ISiteSettings;
}

export const OECustomComponent: React.FunctionComponent<ICoreComponentInfo> = ({ name, user, settings }) => {
    switch (name.toLowerCase()) {
        default:
            return (<OECoreComponent user={user} name={name} settings={settings} />);
    }
};
