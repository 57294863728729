import React, { useEffect, useState } from 'react';
import { FilterDataType, IFilter, IFilterValue } from '../../../core/components/filter/entities/Filter';
import { getSavedFilterValues, saveFilterValues } from '../../../core/components/filter/entities/FilterSave';
import OEDropdown, { DropdownType } from '../../../core/components/form/OEDropdown';
import OEForm, { OEFormGroup, OEFormLabel } from '../../../core/components/form/OEForm';
import OEInput from '../../../core/components/form/OEInput';
import OEWell, { WellStyle } from '../../../core/components/general/OEWell';
import OEConfirmation from '../../../core/components/messaging/OEConfirmation';
import OEErrorList from '../../../core/components/messaging/OEErrorList';
import { IConfirmationMessage, defaultConfirmationMessage } from '../../../core/components/messaging/entities/ConfirmationMessage';
import OETable from '../../../core/components/table/OETable';
import { ColumnType, IColumn } from '../../../core/components/table/entities/Column';
import { IColumnAction } from '../../../core/components/table/entities/ColumnAction';
import { FilterStorage } from '../../../core/entities/FilterStorage';
import { Icon } from '../../../core/entities/Icon';
import { IReport, defaultReport, getReportList } from '../../entities/Report';
import { IReportType, ReportType, getReportTypeList } from '../../entities/ReportType';
import { useGetReportServer } from '../../services/ReportServerService';
import { useDeleteReport, useGetReports, usePutReport } from '../../services/ReportService';
import { useGetReportTypes } from '../../services/ReportTypeService';
import { ISelectedReportAdminViewInfo } from './ReportAdmin';
import ReportFormik from './ReportFormik';
import { navigateToPage } from '../../../core/utilities/URL';

const filter: IFilter = {
    name: 'reportAdminReportList',
    autoSearch: true,
    filters: [
        { name: 'reportTypeID', label: 'Report Type', defaultValue: ReportType.SiteReport },
    ],
};


enum ModalTypes {
    None = 1,
    Edit,
}

const AdminReports: React.FunctionComponent<ISelectedReportAdminViewInfo> = ({ reloadAdmin }) => {
    const { service, setReportTypeId: refreshReports } = useGetReports();
    const { service: deleteService, setReportId: setDeleteId } = useDeleteReport();
    const { service: typeService } = useGetReportTypes();
    const { service: activeService, setItem: setActiveServiceItem } = usePutReport();
    const { service: serverService, setItemId: setReportServerItemId } = useGetReportServer();

    const [reportTypeId, setReportTypeId] = useState<string>(localStorage.getItem(FilterStorage.ReportAdminID) || '');
    const [reportName, setReportName] = useState<string>(localStorage.getItem(FilterStorage.ReportAdminName) || '');
    const [types, setTypes] = useState<IReportType[]>([]);
    const [items, setItems] = useState<IReport[]>([]);
    const [filteredItems, setFilteredItems] = useState<IReport[]>([]);
    const [item, setItem] = useState<IReport>(defaultReport);
    const [showModal, setShowModal] = useState<ModalTypes>(ModalTypes.None);
    const [confirmation, setConfirmation] = useState<IConfirmationMessage>(defaultConfirmationMessage);
    const [errors, setErrors] = useState<string[]>([]);

    useEffect(() => {
        const f: IFilterValue[] = getSavedFilterValues(filter);
        setReportTypeId(f[0].value);
    }, []);

    useEffect(() => {
        if (typeService.result) {
            setTypes(getReportTypeList(typeService.result));
        }
    }, [typeService]);

    useEffect(() => {
        if (service.result) {
            setItems(getReportList(service.result));
        }
    }, [service]);

    useEffect(() => {
        if (activeService.isFinished && !activeService.isSuccess) {
            setErrors([activeService.response.message] || ['An error occurred updating the active status']);
        }
    }, [activeService]);

    useEffect(() => {
        if (deleteService.isFinished && deleteService.isSuccess) {
            reloadAdmin();
        }
        // eslint-disable-next-line
    }, [deleteService]);

    useEffect(() => {
        if (serverService.result) {
            const path: string = serverService.result.path;
            window.open(`${path}/${item.externalId}`, item.name, 'height=800,width=800');

        }
        // eslint-disable-next-line
    }, [serverService]);

    useEffect(() => {
        setFilteredItems(items.filter(q => q.title.toLowerCase().includes(reportName.toLowerCase().trim())));
        // eslint-disable-next-line
    }, [items]);

    useEffect(() => {
        doRefresh();
        localStorage.setItem(FilterStorage.ReportAdminID, reportTypeId);
        saveFilterValues(filter.name, [{ name: filter.filters[0].name, value: reportTypeId, dataType: FilterDataType.String }]);
        // eslint-disable-next-line
    }, [reportTypeId]);

    useEffect(() => {
        localStorage.setItem(FilterStorage.ReportAdminName, reportName);
        setFilteredItems(items.filter(q => q.title.toLowerCase().includes(reportName.toLowerCase().trim())));
        // eslint-disable-next-line
    }, [reportName]);

    const doRefresh = () => {
        reportTypeId && refreshReports(reportTypeId);
    };

    const onCancel = () => {
        setShowModal(ModalTypes.None);
    };

    const onAdd = () => {
        setItem({ ...defaultReport, reportTypeId: reportTypeId || ReportType.SiteReport });
        setShowModal(ModalTypes.Edit);
    };

    const onEdit = (i: IReport) => {
        setItem(i);
        setShowModal(ModalTypes.Edit);
    };

    const onViewReport = (i: IReport) => {
        setItem(i);
        i.reportType && setReportServerItemId(i.reportType.reportServerId);
    };

    const onSuccess = () => {
        setShowModal(ModalTypes.None);
        reloadAdmin();
    };

    const onDelete = (i: string) => {
        setDeleteId(i);
    };

    const onUpdateActive = (i: IReport) => {
        i.isActive = !i.isActive;
        setActiveServiceItem({ ...i });
    };

    const onConfirmDelete = (i: IReport) => {
        setConfirmation({
            ...defaultConfirmationMessage, setConfirmation, item: i.id, show: true, title: "Delete Report?",
            message: `Are you sure you want to delete the report <b>${i.title}</b>?`,
            onOk: onDelete, onCancel: onCancel
        });
    };

    const onConfirmActive = (i: IReport) => {
        setConfirmation({
            ...defaultConfirmationMessage, setConfirmation, item: i, show: true, title: "Update Active Status?",
            message: `Are you sure you want change the active status for the report <b>${i.title}</b>?`,
            onOk: onUpdateActive, onCancel: onCancel
        });
    };

    const onCubeDefinition = (i: IReport) => {
        navigateToPage(`/debug-cubedefinition?id=${i.id}`);
    };

    const onCubeFilters = (i: IReport) => {
        navigateToPage(`/debug-cubefilters?id=${i.id}`);
    };

    const actions: IColumnAction[] = [
        { icon: Icon.Edit, onClick: onEdit, helpText: 'Edit' },
        { icon: Icon.Delete, onClick: onConfirmDelete, condition: "canDelete", notCondition: true, helpText: 'Delete' },
        { icon: Icon.CheckYes, condition: 'isActive', onClick: onConfirmActive, helpText: `Deactivate` },
        { icon: Icon.CheckNo, condition: 'isActive', notCondition: true, onClick: onConfirmActive, helpText: `Activate` },
        { icon: Icon.View, condition: 'isActive', onClick: onViewReport, helpText: `Goto MS Report` },
        { icon: Icon.CubeDefinition, condition: 'isActive', onClick: onCubeDefinition, helpText: 'Cube Definition' },
        { icon: Icon.CubeFilters, condition: 'isActive', onClick: onCubeFilters, helpText: 'Cube Filters' },
    ];

    const columns: IColumn[] = [
        // { id: 'title', idNewLine: 'id', name: 'Title / Id', sort: true, type: ColumnType.Link, onClick: onEdit, helpText: 'Edit Report' },
        // { id: 'reportTypeName', name: 'Type', idNewLine: 'description', sort: true, type: ColumnType.String },
        { id: 'title', name: 'Title', sort: true, type: ColumnType.Link, onClick: onEdit, helpText: 'Edit Report' },
        { id: 'id', name: 'Id', sort: true, type: ColumnType.String },
        { id: 'description', name: 'Description', sort: true, type: ColumnType.String },
        { id: 'path', name: 'Path', sort: true, type: ColumnType.NavigationLink, target: '_blank' },
        { actions, id: 'Actions', width: '20px', name: '', sort: false, type: ColumnType.Actions },
    ];

    return (
        <>
            <OEConfirmation {...confirmation} />
            <OEWell oeStyle={WellStyle.Bordered}  >
                <OEForm className={`row align-items-end`}>
                    <OEFormGroup inline={true} className="m-r-10">
                        <OEFormLabel>Type</OEFormLabel>
                        <OEDropdown
                            onChange={setReportTypeId}
                            values={types}
                            type={DropdownType.String}
                            name="type"
                            value={reportTypeId} />
                    </OEFormGroup>
                    <OEFormGroup inline={true} className="m-r-10">
                        <OEFormLabel>Name</OEFormLabel>
                        <OEInput
                            onChange={setReportName}
                            name="type"
                            value={reportName} />
                    </OEFormGroup>
                </OEForm>
            </OEWell>
            <OEErrorList errors={errors} />

            <OETable
                loading={service.isInProgress}
                loadingMessage="Loading Report List"
                data={filteredItems}
                noDataMessage="There are no reports"
                columns={columns}
                showPagination={true}
                defaultSort="name"
                defaultPageSize={10}
                showExport={true}
                includeRowNumber={true}
                actions={[
                    { icon: Icon.Add, text: 'Add New Report', action: onAdd },
                ]}
            />

            {showModal === ModalTypes.Edit && (
                <ReportFormik onCancel={onCancel} onSuccess={onSuccess} item={item} />
            )}
        </>
    );
};

export default AdminReports;