import React, { Children, useEffect, useState } from 'react';
import ChatBtn from '../../chat/components/ChatBtn';
import ChatWidget from '../../chat/components/ChatWidget';
import { redirectLogin } from '../../core/services/Authentication';
import { IUser } from '../../user/entities/User';
import { SessionController } from '../components/SessionController';
import SiteMenu from '../components/SiteMenu';
import { IRoute } from '../entities/Route';
import { ISiteSettings, saveSiteSettings } from '../entities/SiteSettings';
import SiteFooter from './components/SiteFooter';

export interface ISessionTimer {
    seconds: number;
    isActive: boolean;
    initialized: boolean;
    initializing: boolean;
}


interface IComponentInfo {
    settings: ISiteSettings;
    children?: any;
    routes: IRoute[];
    user: IUser;
}

const Layout: React.FunctionComponent<IComponentInfo> = ({ children, settings }) => {
    const [maintenance, setMaintenance] = useState<boolean>(false);
    const [logout, setLogout] = useState<boolean>(false);

    const [showChat, setShowChat] = useState(false);

    useEffect(() => {
        if (typeof settings === 'string') {
            redirectLogin();
        }
        else {
            try {
                saveSiteSettings(settings);
                setMaintenance(settings.maintenance && !settings.canDebug);

                if (settings.maintenance && !settings.canDebug && !window.location.pathname.includes('maintenance')) {
                    window.location.href = "/maintenance";
                }
                else if (!settings.maintenance && window.location.pathname.includes('maintenance')) {
                    window.location.href = "/home";
                }
            }
            catch {
            }
        }
        // eslint-disable-next-line
    }, [settings]);


    const onLogout = () => {
        setLogout(true);
    }

    return (
        <SessionController settings={settings} logout={logout} >
            <main className="main" data-selected-menu={localStorage.getItem('nimbleSelectedMenu')}>
                <SiteMenu settings={settings} maintenance={maintenance} onLogout={onLogout} />

                <div className="nd-body">
                    {Children.map(children, (i) => i)}
                </div>

                <SiteFooter />

                {settings.chatbotEnabled && (
                    <>
                        <ChatBtn showChat={showChat} setShowChat={setShowChat} />
                        <ChatWidget showChat={showChat} setShowChat={setShowChat} />
                    </>
                )}
            </main>
        </SessionController>
    );
};

export default Layout;
