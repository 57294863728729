import { IBaseList } from "../../core/entities/BaseList";
import { IRoute } from "../../site/entities/Route";
import { defaultReportServer, IReportServer } from "./ReportServer";


export enum SiteReportIds {
    EmbeddedFilters = 'c4c80efd-4dc1-4b99-91b3-25e427af368c',
}

export enum ReportType {
    SiteReport = '3cd8ca26-d4b8-59c1-263a-3a0284a1cc6e',
    MicrostrategyPaginatedTypeID = 'd68cf240-dd90-5fd8-bed0-3a11f9de0e6c',
    PowerBI = '3fe9986b-fccc-f6f0-f9e1-3a0de6c7251c',
    PowerBIPaginated = '7fa4a655-69fa-4f3a-83e8-8cfae4d36e40',
    ReportDownload = '080e2e54-aca1-443e-9551-fd70587cd600'
}

export interface IReportType extends Record<any, any> {
    id: string;
    name: string;
    description: string;
    route: string;
    routeId: number;
    reportServerId: string;
    reportServer: string;
    canDelete: boolean;
    parameters: string;
    server: IReportServer;
}

export const defaultReportType: IReportType = {
    id: '', name: '', description: '', route: '', routeId: -1, canDelete: false,
    reportServer: '', reportServerId: '', parameters: '', server: defaultReportServer
};

export interface IReportTypeListItem {
    reportType: IReportType;
    route: IRoute;
    reportServer: IReportServer;
}

export const getReportTypeList = (i: IBaseList<IReportTypeListItem>): IReportType[] => {
    const d: IReportType[] = [];
    for (const v of i.items) {
        d.push({
            ...defaultReportType,
            ...v.reportType,
            routeId: v.route ? v.route.id : 0,
            route: v.route ? v.route.name : '',
            reportServer: v.reportServer.name
        });
    }
    for (const v of d) {
        v.childCount = d.filter(q => q.parentId === v.id).length;
    }
    return d;
};

export const isPowerBIReport = (reportTypeId: string): boolean => {
    return reportTypeId === ReportType.PowerBI || reportTypeId === ReportType.PowerBIPaginated;
}

export const isSiteReport = (reportTypeId: string): boolean => {
    return reportTypeId === ReportType.SiteReport;
}

