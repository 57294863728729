import React, { useEffect, useState } from 'react';
import OEHeading from '../../../core/components/general/OEHeading';
import OETabs, { OETab } from '../../../core/components/tabs/OETabs';
import { Icon } from '../../../core/entities/Icon';
import SiteReport from './SiteReport';
import { ReportType } from '../../entities/ReportType';
import { ISiteReport } from '../../entities/SiteReport';
import { useGetSiteReports } from '../../services/SiteReportService';

const SiteReports: React.FunctionComponent = () => {
    const { service } = useGetSiteReports();

    const [reports, setReports] = useState<ISiteReport[]>([]);

    useEffect(() => {
        if (service.result) {
            setReports(service.result.filter(q => q.isActive && q.id === ReportType.SiteReport));
        }
    }, [service]);

    const onTabChange = (s: any) => {
        reports.filter(q => q.id === s)[0].visible = true;
        setReports([...reports]);
    }

    return (
        <div className="site-report">
            <OEHeading className="m-t-10" text="Site Reports" icon={Icon.Report} />
            {reports.length > 0 && (
                <OETabs className="fst-italic small" id="sitereports" onTabChange={onTabChange} defaultTab={reports[0].id} >
                    {reports.map((item, index) =>
                        <OETab key={index} title={`${item.title}`} eventKey={item.id}>
                            {item.visible && (
                                <SiteReport item={item} />
                            )}
                        </OETab>

                    )}
                </OETabs>
            )}
        </div>
    );
};

export default SiteReports;