import React from 'react';
import OEIcon from '../../../core/components/general/OEIcon';
import { Icon } from '../../../core/entities/Icon';
import { IMicroStrategyFilter } from '../../../report-microstrategy/entities/MicroStrategyFilters';
import { IReport } from '../../entities/Report';
import { ReportDataDownloadType } from '../../entities/ReportConfiguration';

interface IReportFlagInfo {
    report: IReport;
    dataDownload: ReportDataDownloadType;
    pageFilters: IMicroStrategyFilter[];
}

const ReportDataDownload: React.FunctionComponent<IReportFlagInfo> = () => {
    return (
        <span className="m-l-5">
            <OEIcon icon={Icon.Download} />
        </span>
    );
};

export default ReportDataDownload;