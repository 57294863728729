import { IBaseAuditedEntity } from '../../core/entities/BaseAuditedEntity';
import { IBaseList } from "../../core/entities/BaseList";
import { defaultReport, getReportPath, IReport } from './Report';
import { getReportConfigurations, IReportConfigurationItem } from './ReportConfiguration';
import { defaultReportGroup, IReportGroup } from './ReportGroup';
import { ReportType } from './ReportType';

export interface IReportList extends IBaseAuditedEntity {
    id: string;
    title: string;
    description: string;
    sortOrder: number;
    width: number;
    height: number;
    reportId: string;
    reportGroupId: string;
    reportTypeId: string;
    report?: string;
    reportGroup?: string;
    isActive: boolean;
    reportActive: boolean;
    configuration: string;
    filters: string;
    path: string;
    configurations: IReportConfigurationItem[];
    isDownload: boolean;
}

export const defaultReportList: IReportList = {
    id: '', title: '', description: '', sortOrder: -1, width: 0, height: 0,
    reportGroupId: '', reportId: '', reportTypeId: '', isActive: true, reportActive: true,
    configuration: '', configurations: [], path: '', filters: '', isDownload: false
};

export interface IReportListItem extends IBaseAuditedEntity {
    reportList: IReportList;
    report: IReport;
    reportGroup: IReportGroup;
}

export const defaultReportListItem: IReportListItem = {
    report: defaultReport, reportList: defaultReportList, reportGroup: defaultReportGroup
};

export const getReportList = (i: IBaseList<IReportListItem>): IReportList[] => {
    const d: IReportList[] = [];
    for (const v of i.items) {
        d.push({
            ...defaultReportList,
            ...v.reportList,
            path: v.report ? getReportPath(v.reportList.configuration, v.reportList.reportId, v.report.reportTypeId) : '',
            isDownload: v.report.reportTypeId === ReportType.ReportDownload ? true : false,
            configurations: getReportConfigurations(v.reportList.configuration),
            reportGroup: v.reportGroup ? v.reportGroup.title : 'No Report Group Selected',
            report: v.report ? v.report.title : 'No Report Selected',
            reportType: v.report ? v.report.reportTypeName : 'No Report Selected',
            reportId: v.report ? v.report.id : 'No Report Selected',
            reportActive: v.report ? v.report.isActive : false
        });
    }
    return d;
};

